<template>
  <component v-bind="$attrs" :is="tagType" :aria-labelledby="labelledBy" :to="to" :class="classNames.root">
    <slot :labelledBy name="header">
      <UiInfo
        v-if="title"
        is="header"
        :title="title"
        :subtitle="subtitle"
        :loading="loading"
        :has-image="hasImage"
        :classes="classNames.header"
        :attributes="{ title: { id: labelledBy } }"
      />
    </slot>

    <div :class="['ui-card__body', requiresLoader ? null : classNames.body]">
      <UiLoadingAnimation
        v-if="requiresLoader"
        :loading="loading!"
        :container-size="loadingContainerSize"
        :class="classNames.body"
        :classes="{ loadingWrapper: 'h-full' }"
      >
        <slot :labelledBy />
      </UiLoadingAnimation>

      <slot v-else :labelledBy />
    </div>

    <slot name="footer" />
  </component>
</template>

<script setup lang="ts">
import { type VariantProps, cva } from "class-variance-authority"
import type { ClassValue } from "clsx"

import type { UiInfoClasses } from "./Info.vue"

const attrs = useAttrs()
const id = useId()

export interface UiCardClasses {
  root: ClassValue
  header: Partial<UiInfoClasses>
  body: ClassValue
}

export interface UiCardProps {
  is?: string
  to?: TypedRouteProps | string
  title?: string
  subtitle?: string
  hasImage?: boolean
  loading?: boolean
  loadingContainerSize?: number | string
  variant?: VariantProps<typeof cardVariants>["variant"]
  border?: VariantProps<typeof cardVariants>["border"]
  bodyPadding?: VariantProps<typeof cardBodyVariants>["padding"]
  classes?: Partial<UiCardClasses>
}

const props = withDefaults(defineProps<UiCardProps>(), { loading: undefined })

const cardVariants = cva("relative flex flex-col w-full rounded-md overflow-hidden", {
  variants: {
    variant: {
      default: "",
      elevated: "shadow-elevated",
    },
    border: {
      none: "",
      default: "border border-border",
      dashed:
        "border-transparent before:content-[''] before:pointer-events-none before:absolute before:inset-0 before:bg-card-svg",
      elevated: "shadow-elevated",
    },
  },
  defaultVariants: {
    variant: "default",
    border: "default",
  },
})

const cardBodyVariants = cva("flex-1", {
  variants: {
    variant: { default: "" },
    padding: { default: "p-m md:p-s", none: "p-0 md:p-0" },
  },
  defaultVariants: {
    variant: "default",
    padding: "default",
  },
})

const classNames = computed((): Required<UiCardClasses> => {
  return {
    root: cn(
      cardVariants({
        variant: props.variant,
        border: props.border,
        class: props.classes?.root,
      })
    ),
    header: {
      root: cn("p-m md:p-s", props.classes?.header?.root),
      title: cn({ "text-muted-foreground": !props.subtitle }, props.classes?.header?.title),
      subtitle: cn({ "text-muted-foreground": !props.subtitle }, props.classes?.header?.subtitle),
    },
    body: cn(cardBodyVariants({ padding: props.bodyPadding }), props.classes?.body),
  }
})

const tagType = computed(() => {
  if (props.to) return resolveComponent("UiLink")
  else if (attrs.onClick) return "button"
  else if (props.is) return props.is

  return "div"
})

const labelledBy = computed(() => "ui-card-title-" + id)
const requiresLoader = computed(() => !isNullOrUndefined(props.loading))
</script>
